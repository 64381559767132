// import { defineNuxtPlugin } from '#app'
import formbricks from '@formbricks/js'
import { useUserStore } from '~/store/user.js';

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig();
  const store = useUserStore()

  if (typeof window !== "undefined") {
    formbricks.init({
      apiHost: "https://forms.pixta.me",
      debug: process.env.NODE_ENV !== 'production',
      environmentId: runtimeConfig.public.formbricksEnvironmentId
    });
    console.log('bricks -> ', runtimeConfig.public.formbricksEnvironmentId)
  }

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    // if (!import.meta.env.SSR && !_to.path.startsWith('/meus_eventos')) {
    if (typeof formbricks !== "undefined") {
      formbricks.registerRouteChange()
    }
  });

  return {
    provide: {
      formbricks: () => formbricks
    }
  }
})
